
import { Diagram, ModelConnection, ModelObject, PermissionType, Task } from '@icepanel/platform-api-client'
import { Easing, Tween } from '@tweenjs/tween.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { deltaToMarkdown, markdownStats, markdownToDelta } from '@/helpers/markdown-delta'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'
import Quill, { QuillInstance } from '@/plugins/quill'

import * as analytics from '../helpers/analytics'
import { EditorModule } from '../store'

type EditorObject = {
  description: string
  id: string
  save: (markdown: string) => void
  type: Diagram['type'] | ModelObject['type'] | 'connection'
  version: number
}

type EditorType = 'rich-text' | 'markdown'

const Keyboard = Quill.import('modules/keyboard')

@Component({
  name: 'EditorDescriptionEditor'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly container!: HTMLElement
  @Ref() readonly editor!: HTMLElement
  @Ref() readonly toolbar!: HTMLElement
  @Ref() readonly toolbarContainer!: HTMLElement
  @Ref() readonly placeholder?: HTMLElement

  @Prop({ default: null }) readonly object!: ModelObject | ModelConnection | Diagram | null
  @Prop({ default: false }) readonly editingMessage!: boolean
  @Prop() readonly permission!: PermissionType
  @Prop({ default: false, type: Boolean }) readonly scroll!: boolean

  quill!: QuillInstance
  quillOriginalMatchers: any

  focus: EditorObject | null = null

  editorType: EditorType = 'rich-text'
  alertOpacity = 0
  toolbarAnimation: Tween<{ alertOpacity: number, toolbarMarkdownOpacity: number, toolbarQuillOpacity: number }> | null = null
  toolbarQuillOpacity = 0.2
  toolbarMarkdownOpacity = 0.2

  clickListener?: (event: MouseEvent) => void
  keydownListener?: (event: KeyboardEvent) => void
  blurListener?: (event: Event) => void
  textChangedListener?: () => void

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get metaKey () {
    if (window.navigator.platform.toLowerCase().includes('mac')) {
      return 'Cmd'
    } else {
      return 'Ctrl'
    }
  }

  get type () {
    if (this.object && 'type' in this.object && (this.object.type === 'context-diagram' || this.object.type === 'app-diagram' || this.object.type === 'component-diagram')) {
      return 'diagram'
    } else if (this.object && 'type' in this.object && (this.object.type === 'root' || this.object.type === 'actor' || this.object.type === 'system' || this.object.type === 'app' || this.object.type === 'store' || this.object.type === 'component' || this.object.type === 'group')) {
      return 'object'
    } else if (this.object && !('type' in this.object)) {
      return 'connection'
    } else {
      return null
    }
  }

  get editorObject (): EditorObject | null {
    if (this.object) {
      const { id, description, version } = this.object
      if ('type' in this.object && (this.object.type === 'context-diagram' || this.object.type === 'app-diagram' || this.object.type === 'component-diagram')) {
        return {
          description: description || '',
          id,
          save: markdown => {
            const revertTasks: Task[] = [{
              id,
              props: {
                description
              },
              type: 'diagram-update'
            }, {
              route: this.$route,
              type: 'navigation'
            }]

            const { diagram, diagramUpdate } = this.diagramModule.generateDiagramCommit(id, {
              description: markdown
            })
            this.diagramModule.setDiagramVersion(diagram)
            this.editorModule.addToTaskQueue({
              func: () => this.diagramModule.diagramUpdate({
                diagramId: id,
                landscapeId: this.currentLandscape.id,
                props: diagramUpdate,
                versionId: this.currentVersion.id
              })
            })

            this.editorModule.addTaskList({
              revertTasks,
              tasks: [{
                id: diagram.id,
                props: diagramUpdate,
                type: 'diagram-update'
              }, {
                route: this.$route,
                type: 'navigation'
              }]
            })
          },
          type: this.object.type,
          version
        }
      } else if ('type' in this.object && (this.object.type === 'root' || this.object.type === 'actor' || this.object.type === 'system' || this.object.type === 'app' || this.object.type === 'store' || this.object.type === 'component' || this.object.type === 'group')) {
        return {
          description: description || '',
          id,
          save: async markdown => {
            const tasks: Task[] = []
            const revertTasks: Task[] = []

            if (this.currentDiagram?.status === 'draft') {
              revertTasks.push({
                id: this.currentDiagram.id,
                props: {
                  tasksProposed: {
                    $append: [{
                      id,
                      props: {
                        description
                      },
                      type: 'model-object-update'
                    }]
                  }
                },
                type: 'diagram-content-update'
              }, {
                route: this.$route,
                type: 'navigation'
              })

              const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
                tasksProposed: {
                  $append: [{
                    id,
                    props: {
                      description: markdown
                    },
                    type: 'model-object-update'
                  }]
                }
              })
              this.diagramModule.setDiagramContentVersion(diagramContent)
              this.editorModule.addToTaskQueue({
                func: () => this.diagramModule.diagramContentUpdate({
                  diagramId: diagramContent.id,
                  landscapeId: this.currentLandscape.id,
                  props: diagramContentUpdate,
                  versionId: this.currentVersion.id
                })
              })

              tasks.push({
                id: diagramContent.id,
                props: diagramContentUpdate,
                type: 'diagram-content-update'
              }, {
                route: this.$route,
                type: 'navigation'
              })
            } else {
              revertTasks.push({
                id,
                props: {
                  description
                },
                type: 'model-object-update'
              }, {
                route: this.$route,
                type: 'navigation'
              })

              const { object, objectUpdate } = this.modelModule.generateObjectCommit(id, {
                description: markdown
              })
              this.modelModule.setObjectVersion(object)
              this.editorModule.addToTaskQueue({
                func: () => this.modelModule.objectUpdate({
                  landscapeId: this.currentLandscape.id,
                  objectId: id,
                  props: objectUpdate,
                  versionId: this.currentVersion.id
                })
              })

              tasks.push({
                id: object.id,
                props: objectUpdate,
                type: 'model-object-update'
              }, {
                route: this.$route,
                type: 'navigation'
              })
            }

            this.editorModule.addTaskList({
              revertTasks,
              tasks
            })
          },
          type: this.object.type,
          version
        }
      } else if (!('type' in this.object)) {
        return {
          description: description || '',
          id,
          save: markdown => {
            const tasks: Task[] = []
            const revertTasks: Task[] = []

            if (this.currentDiagram?.status === 'draft') {
              revertTasks.push({
                id: this.currentDiagram.id,
                props: {
                  tasksProposed: {
                    $append: [{
                      id,
                      props: {
                        description
                      },
                      type: 'model-connection-update'
                    }]
                  }
                },
                type: 'diagram-content-update'
              }, {
                route: this.$route,
                type: 'navigation'
              })

              const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
                tasksProposed: {
                  $append: [{
                    id,
                    props: {
                      description: markdown
                    },
                    type: 'model-connection-update'
                  }]
                }
              })
              this.diagramModule.setDiagramContentVersion(diagramContent)
              this.editorModule.addToTaskQueue({
                func: () => this.diagramModule.diagramContentUpdate({
                  diagramId: diagramContent.id,
                  landscapeId: this.currentLandscape.id,
                  props: diagramContentUpdate,
                  versionId: this.currentVersion.id
                })
              })

              tasks.push({
                id: diagramContent.id,
                props: diagramContentUpdate,
                type: 'diagram-content-update'
              }, {
                route: this.$route,
                type: 'navigation'
              })
            } else {
              revertTasks.push({
                id,
                props: { description },
                type: 'model-connection-update'
              }, {
                route: this.$route,
                type: 'navigation'
              })

              const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(id, {
                description: markdown
              })
              this.modelModule.setConnectionVersion(connection)
              this.editorModule.addToTaskQueue({
                func: () => this.modelModule.connectionUpdate({
                  connectionId: id,
                  landscapeId: this.currentLandscape.id,
                  props: connectionUpdate,
                  versionId: this.currentVersion.id
                })
              })

              tasks.push({
                id: connection.id,
                props: connectionUpdate,
                type: 'model-connection-update'
              }, {
                route: this.$route,
                type: 'navigation'
              })
            }

            this.editorModule.addTaskList({
              revertTasks,
              tasks
            })
          },
          type: 'connection',
          version
        }
      } else {
        throw new Error('Unknown object type for description')
      }
    } else {
      return null
    }
  }

  get descriptionEmpty () {
    return !this.editorObject?.description?.trim()
  }

  get descriptionTemplate () {
    if (this.editorObject) {
      if (
        this.editorObject.type === 'context-diagram' ||
        this.editorObject.type === 'app-diagram' ||
        this.editorObject.type === 'component-diagram'
      ) {
        return '###### This diagram explains\n-\n###### Important links\n-'
      } else if (
        this.editorObject.type === 'connection' ||
        this.editorObject.type === 'actor' ||
        this.editorObject.type === 'system' ||
        this.editorObject.type === 'app' ||
        this.editorObject.type === 'component' ||
        this.editorObject.type === 'store' ||
        this.editorObject.type === 'group'
      ) {
        return '###### Description\n-\n###### Responsibilities\n-\n###### Technical decisions\n-'
      }
    }
  }

  mounted () {
    this.quill = new Quill(this.editor, {
      modules: {
        clickLink: true,
        keyboard: {
          bindings: {
            bold: {
              handler: (range: any, context: any) => {
                if (this.editorType === 'rich-text') {
                  Keyboard.DEFAULTS.bindings.bold.handler.call(this.quill.keyboard, range, context)
                } else {
                  return false
                }
              }
            },
            italic: {
              handler: (range: any, context: any) => {
                if (this.editorType === 'rich-text') {
                  Keyboard.DEFAULTS.bindings.italic.handler.call(this.quill.keyboard, range, context)
                } else {
                  return false
                }
              }
            },
            'list autofill': {
              handler: (range: any, context: any) => {
                if (this.editorType === 'rich-text') {
                  Keyboard.DEFAULTS.bindings['list autofill'].handler.call(this.quill.keyboard, range, context)
                } else {
                  return true
                }
              }
            },
            underline: {
              handler: () => false
            }
          }
        },
        magicUrl: true,
        table: true,
        tableUI: true,
        toolbar: this.toolbar
      },
      readOnly: this.permission === 'read',
      theme: 'snow'
    })
    this.quillOriginalMatchers = this.quill.clipboard.matchers

    this.textChangedListener = () => {
      if (this.editorObject) {
        this.save(this.editorObject)
      }
    }
    this.quill.on('text-change', this.textChangedListener)

    this.clickListener = this.onClick.bind(this)
    window.addEventListener('click', this.clickListener)

    this.keydownListener = this.onKeydown.bind(this)
    window.addEventListener('keydown', this.keydownListener)

    this.blurListener = this.stopEditing.bind(this)
    window.addEventListener('blur', this.blurListener)

    if (this.editorObject) {
      const delta = markdownToDelta(this.editorObject.description)
      this.quill.setContents(delta)
    }
  }

  beforeDestroy () {
    this.stopEditing()
  }

  destroyed () {
    if (this.textChangedListener) {
      this.quill.off('text-change', this.textChangedListener)
    }
    if (this.clickListener) {
      window.removeEventListener('click', this.clickListener)
    }
    if (this.keydownListener) {
      window.removeEventListener('keydown', this.keydownListener)
    }
    if (this.blurListener) {
      window.removeEventListener('blur', this.blurListener)
    }
  }

  @Watch('editorObject')
  onEditorObjectChanged (object: EditorObject | null, prevObject: EditorObject | null) {
    if (!prevObject && object) {
      if (this.editorType === 'markdown') {
        this.quill.setText(object.description)
      } else {
        const delta = markdownToDelta(object.description)
        this.quill.setContents(delta)
      }
    } if (prevObject && !object) {
      this.save.cancel()
      this.closeEvent(prevObject)
      this.saveExec(prevObject)
      this.quill.setText('')
      if (this.editor.contains(document.activeElement)) {
        this.quill.blur()
      }
    } else if (prevObject && object && object.id !== prevObject.id) {
      this.save.cancel()
      this.closeEvent(prevObject)
      this.saveExec(prevObject)
      if (this.editorType === 'markdown') {
        this.quill.setText(object.description)
      } else {
        const delta = markdownToDelta(object.description)
        this.quill.setContents(delta)
      }
      this.quill.blur()
    } else if (prevObject && object && object.description !== prevObject.description && this.focus?.id !== object.id) {
      if (this.editorType === 'markdown') {
        this.quill.setText(object.description)
      } else {
        const delta = markdownToDelta(object.description)
        const diff = this.quill.getContents().diff(delta)
        this.quill.updateContents(diff)
      }
    }
  }

  @Watch('editingMessage')
  onAlertMessageChanged (editingMessage: string | null) {
    if (this.permission !== 'read') {
      if (editingMessage) {
        this.quill.disable()
        this.animateToolbar({
          editingMessage: true,
          toolbarMarkdown: false,
          toolbarQuill: false
        })
      } else {
        this.quill.enable()
        this.animateToolbar({
          editingMessage: false,
          toolbarMarkdown: this.focus ? this.editorType === 'markdown' : false,
          toolbarQuill: this.focus ? this.editorType !== 'markdown' : false
        })
      }
    }
  }

  animateToolbar ({
    editingMessage,
    toolbarMarkdown,
    toolbarQuill
  }: {
    editingMessage: boolean
    toolbarMarkdown: boolean
    toolbarQuill: boolean
  }) {
    if (this.toolbarAnimation) {
      this.toolbarAnimation.stop()
      this.toolbarAnimation = null
    }

    this.toolbarAnimation = new Tween({
      alertOpacity: this.alertOpacity,
      toolbarMarkdownOpacity: this.toolbarMarkdownOpacity,
      toolbarQuillOpacity: this.toolbarQuillOpacity
    })
      .to({
        alertOpacity: editingMessage ? 1 : 0,
        toolbarMarkdownOpacity: editingMessage ? 0 : toolbarMarkdown ? 1 : 0.2,
        toolbarQuillOpacity: editingMessage ? 0 : toolbarQuill ? 1 : 0.2
      })
      .easing(Easing.Quartic.InOut)
      .duration(250)
      .onUpdate(o => {
        this.alertOpacity = o.alertOpacity
        this.toolbarMarkdownOpacity = o.toolbarMarkdownOpacity
        this.toolbarQuillOpacity = o.toolbarQuillOpacity
      })
      .onComplete(() => {
        this.toolbarAnimation = null
      })
      .start()
  }

  startEditing () {
    if (!this.editingMessage && this.focus?.id !== this.editorObject?.id && this.editorObject && this.permission !== 'read') {
      this.focus = { ...this.editorObject }
      this.animateToolbar({
        editingMessage: false,
        toolbarMarkdown: true,
        toolbarQuill: true
      })
      this.quill.focus()
      this.$emit('edit-start', this.focus.id)
      analytics.editorDescriptionUpdate.time()
    }
  }

  stopEditing () {
    if (this.focus && this.permission !== 'read') {
      this.setEditorType('rich-text')
      this.animateToolbar({
        editingMessage: false,
        toolbarMarkdown: false,
        toolbarQuill: false
      })
      if (this.editor.contains(document.activeElement)) {
        this.quill.blur()
      }
      this.save.cancel()
      if (this.editorObject) {
        this.closeEvent(this.editorObject)
        this.saveExec(this.editorObject)
      }
      this.$emit('edit-end', this.focus.id)
      this.focus = null
      const markdown = deltaToMarkdown(this.quill.getContents())
      const delta = markdownToDelta(markdown)
      const diff = this.quill.getContents().diff(delta)
      this.quill.updateContents(diff)
    }
  }

  setEditorType (editorType: EditorType) {
    if (editorType === 'rich-text' && this.editorType === 'markdown') {
      const delta = markdownToDelta(this.quill.getText())
      this.quill.setContents(delta)
      this.quill.clipboard.matchers = this.quillOriginalMatchers
      this.animateToolbar({
        editingMessage: false,
        toolbarMarkdown: true,
        toolbarQuill: true
      })
    } else if (editorType === 'markdown' && this.editorType === 'rich-text') {
      const markdown = deltaToMarkdown(this.quill.getContents())
      this.quill.setText(markdown)
      this.quill.clipboard.matchers = [
        this.quillOriginalMatchers[0],
        this.quillOriginalMatchers[1],
        this.quillOriginalMatchers[2],
        this.quillOriginalMatchers[3]
      ]
      this.animateToolbar({
        editingMessage: false,
        toolbarMarkdown: true,
        toolbarQuill: false
      })
    }
    this.editorType = editorType
  }

  toggleMarkdown () {
    if (this.focus) {
      if (this.editorType === 'markdown') {
        this.setEditorType('rich-text')
      } else {
        this.setEditorType('markdown')
      }
      this.quill.setSelection(0, 0)
      this.quill.focus()
      setTimeout(() => {
        const element = this.editor.getElementsByClassName('ql-editor').item(0)
        if (element) {
          element.scrollTop = 0
        }
      })
    } else {
      this.startEditing()
      this.setEditorType('markdown')
    }
  }

  onClick (event: MouseEvent) {
    const quill = this.quill as any
    const target = event.target as HTMLElement
    const isEditor = this.quill.root.contains(target)
    const isTooltip = quill.theme.tooltip.root.contains(target)
    const isToolbar = quill.theme.modules.toolbar.container.contains(target)
    const isToolbarControl = quill.theme.modules.toolbar.controls.some((o: any) => o[1].contains(target))
    const isPlaceholder = this.placeholder?.contains(target)
    const isEditorContainer = isEditor || isTooltip || isToolbar || isToolbarControl || isPlaceholder

    if (this.focus) {
      if (!isEditorContainer) {
        this.stopEditing()
      }
    } else {
      if (isEditorContainer) {
        this.startEditing()
      }
    }
  }

  onKeydown (event: KeyboardEvent) {
    const metaKey = window.navigator.platform.toLowerCase().includes('mac') ? event.metaKey : event.ctrlKey
    if (event.key.toLowerCase() === 'm' && event.shiftKey && metaKey && this.permission !== 'read') {
      this.toggleMarkdown()
      event.preventDefault()
    }
    if (this.focus && ((event.key.toLowerCase() === 's' && metaKey && !event.shiftKey) || event.code === 'Escape') && this.permission !== 'read') {
      this.stopEditing()
      event.preventDefault()
    }
  }

  save = debounce(this.saveExec.bind(this), 700)

  saveExec (editorObject: EditorObject) {
    if (this.permission !== 'read' && this.focus?.id === editorObject.id) {
      let markdown: string
      if (this.editorType === 'markdown') {
        markdown = this.quill.getText().trim()
      } else {
        markdown = deltaToMarkdown(this.quill.getContents()).trim()
      }
      if (markdown !== editorObject.description) {
        editorObject?.save(markdown)
      }
    }
  }

  closeEvent (editorObject: EditorObject) {
    if (this.permission !== 'read' && this.focus?.id === editorObject.id) {
      let markdown: string
      if (this.editorType === 'markdown') {
        markdown = this.quill.getText().trim()
      } else {
        markdown = deltaToMarkdown(this.quill.getContents()).trim()
      }
      if (markdown !== editorObject.description) {
        const stats = markdownStats(editorObject.description)
        analytics.editorDescriptionUpdate.track(this, {
          editorDocumentationBlockquoteCount: stats.blockquoteCount,
          editorDocumentationCharacterCount: stats.textCharacterCount,
          editorDocumentationCodeCount: stats.codeCount,
          editorDocumentationDividerCount: stats.dividerCount,
          editorDocumentationEmphasisCount: stats.emphasisCount,
          editorDocumentationHeading1Count: stats.heading1Count,
          editorDocumentationHeading2Count: stats.heading2Count,
          editorDocumentationHeading3Count: stats.heading3Count,
          editorDocumentationHeading4Count: stats.heading4Count,
          editorDocumentationHeading5Count: stats.heading5Count,
          editorDocumentationHeading6Count: stats.heading6Count,
          editorDocumentationImageCount: stats.imageCount,
          editorDocumentationInlineCodeCount: stats.inlineCodeCount,
          editorDocumentationLinkCount: stats.linkCount,
          editorDocumentationListCount: stats.listCount,
          editorDocumentationListItemCount: stats.listItemCount,
          editorDocumentationObjectType: editorObject.type,
          editorDocumentationParagraphCount: stats.paragraphCount,
          editorDocumentationStrikethroughCount: stats.strikethroughCount,
          editorDocumentationStrongCount: stats.strongCount,
          editorDocumentationTableCellCount: stats.tableCellCount,
          editorDocumentationTableCount: stats.tableCount,
          editorDocumentationTableRowCount: stats.tableRowCount,
          editorDocumentationType: this.editorType,
          editorDocumentationWordCount: stats.textWordCount,
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId]
        })
      }
    }
  }

  addTemplate () {
    if (this.editorObject && this.descriptionTemplate) {
      this.startEditing()
      const delta = markdownToDelta(this.descriptionTemplate)
      this.quill.setContents(delta)
      this.quill.setSelection(this.quill.getLength(), 0)

      analytics.editorDescriptionTemplate.track(this, {
        editorDocumentationObjectType: this.editorObject.type,
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }
}
